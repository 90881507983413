/*
Theme Name: BlankSlate Child
Theme URI: http://tidythemes.com/blankslate/
Description: Child theme for the BlankSlate theme. Allows you to fully style and customize the features of your theme without them being overwritten upon upgrading the main BlankSlate theme. Learn more: http://codex.wordpress.org/Child_Themes
Author: TidyThemes
Author URI: http://tidythemes.com/
Template: blankslate
*/
@import url("../bootstrap/css/bootstrap.min.css");
@import url("jquery.fancybox.css");
/**
 * VARS
 */
@import url(https://fonts.googleapis.com/css?family=Raleway:600,400,700);
@import url(https://fonts.googleapis.com/css?family=Cantata+One);
@import url(https://fonts.googleapis.com/css?family=Pompiere);
@import url(https://fonts.googleapis.com/css?family=Droid+Serif:400italic,700italic);
img.lazy {
  background: rgba(255, 255, 255, 0.5) url(../img/loader.gif) center center no-repeat;
}
.intrinsic-ratio {
  box-sizing: border-box;
  position: relative;
  width: 100%;
  height: 0;
  margin: 0;
  padding: 0;
}
.intrinsic-ratio.one-by-one {
  padding-bottom: 100%;
}
.intrinsic-ratio.four-by-three {
  padding-bottom: 75%;
}
.intrinsic-ratio.sixteen-by-nine {
  padding-bottom: 56.25%;
}
@font-face {
  font-family: 'socicon';
  src: url('../fonts/socicon.eot');
  src: url('../fonts/socicon.eot?#iefix') format('embedded-opentype'), url('../fonts/socicon.woff') format('woff'), url('../fonts/socicon.woff2') format('woff2'), url('../fonts/socicon.ttf') format('truetype'), url('../fonts/socicon.svg#sociconregular') format('svg');
  font-weight: normal;
  font-style: normal;
  text-transform: initial;
}
body {
  font-family: "Raleway", sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #333333;
  background: #fafafa url(../img/white-paperboard.png) top left repeat;
}
h1,
h2,
h3 {
  position: relative;
  display: block;
  margin: 0 auto 16px;
  font-family: 'Cantata One', serif;
  font-weight: 400;
  color: #222222;
  text-transform: uppercase;
  text-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  overflow: hidden;
}
h1 > span,
h2 > span,
h3 > span {
  display: inline;
}
h1.accent,
h2.accent,
h3.accent {
  margin: 0 auto;
  padding: 0 10%;
  text-align: center;
}
h1.accent .accent-left,
h2.accent .accent-left,
h3.accent .accent-left,
h1.accent .accent-right,
h2.accent .accent-right,
h3.accent .accent-right {
  position: absolute;
  display: block;
  top: 0;
  bottom: 0;
  background: transparent url(../img/header-accent.gif) repeat-x center center;
  min-width: 9%;
}
h1.accent .accent-left,
h2.accent .accent-left,
h3.accent .accent-left {
  left: 0;
}
h1.accent .accent-right,
h2.accent .accent-right,
h3.accent .accent-right {
  right: 0;
}
h1 {
  font-size: 48px;
}
h2 {
  font-size: 36px;
}
p {
  margin: 0 0 16px;
}
a {
  color: #333333;
  text-decoration: none;
  transition: all 0.3s;
}
a:focus,
a:hover {
  color: #f9b3c6;
  text-decoration: underline;
  transition: all 0.3s;
}
hr {
  margin-top: 24px;
  margin-bottom: 24px;
}
.btn,
.btn-group-lg > .btn,
.btn-lg {
  border-radius: 3px;
  outline-width: 0 !important;
}
.btn-primary {
  background-color: #222222;
  border-color: #222222;
  border-radius: 50px;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  transition: color 0.15s, background 0.15s, box-shadow 0.15s;
}
.btn-primary.focus,
.btn-primary:focus {
  background-color: #333333;
  border-color: #333333;
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.15);
  transition: color 0.15s, background 0.15s, box-shadow 0.15s;
}
.btn-primary:hover,
.btn-primary:active,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary:active:hover:focus {
  background-color: #f9b3c6;
  border-color: #f9b3c6;
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.15);
  transition: color 0.15s, background 0.15s, box-shadow 0.15s;
}
blockquote {
  border: none;
  text-align: left;
  font-family: 'Droid Serif', serif;
  font-weight: 700;
  font-style: italic;
  font-size: 160%;
  color: #2b2b2b;
  quotes: "\201C" "\201D" "\2018" "\2019";
  text-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
}
blockquote::before {
  content: open-quote;
  font-size: 4em;
  line-height: 0.1em;
  margin-right: 0.05em;
  vertical-align: -0.38em;
}
blockquote p {
  display: inline;
}
.form-control {
  border-color: #dddddd;
}
.form-control:focus {
  border-color: #f9b3c6;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(243, 108, 145, 0.4);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(243, 108, 145, 0.4);
}
html,
body,
#wrapper {
  position: relative;
  display: block;
  box-sizing: border-box;
  width: 100%;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
#wrapper {
  overflow: hidden;
}
#container {
  display: inline-block;
}
#wrapper,
#header,
#branding,
#container,
#footer {
  position: relative;
  width: 100%;
}
/**
 * Rows
 */
.row {
  position: relative;
  box-sizing: border-box;
  margin: auto;
}
.row:before,
.row:after {
  content: " ";
  display: table;
}
.row:after {
  clear: both;
}
.row.full {
  width: 100%;
}
.row.contained {
  width: 96.667%;
  max-width: 1000px;
  padding-left: 16px;
  padding-right: 16px;
}
.row.padded {
  padding-top: 24px;
  padding-bottom: 24px;
}
.row.padded .row-header {
  padding-bottom: 32px;
}
@media (min-width: 768px) {
  .row.contained {
    width: 93.333%;
  }
  .row.padded {
    padding-top: 32px;
    padding-bottom: 32px;
  }
  .row.padded .row-header {
    padding-bottom: 48px;
  }
}
@media (min-width: 960px) {
  .row.contained {
    width: 90%;
  }
  .row.padded {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  .row.padded .row-header {
    padding-bottom: 64px;
  }
}
/**
 * Header
 */
#header {
  background: #ffffff;
  border-bottom: 1px solid #dddddd;
  box-shadow: 0 0 5px rgba(34, 34, 34, 0.15);
  z-index: 10;
}
/**
 * Branding
 */
#branding {
  box-sizing: border-box;
  padding: 16px;
  padding-bottom: 32px;
}
#branding .logo {
  display: block;
  margin: auto;
  text-align: center;
}
#branding .logo img {
  display: inline-block;
  width: 260px;
  height: auto;
}
#branding .social-follow-links {
  margin-bottom: 16px;
  text-align: center;
}
@media (min-width: 640px) {
  #branding .logo img {
    width: 300px;
  }
}
@media (min-width: 768px) {
  #branding {
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  #branding {
    padding-top: 32px;
  }
  #branding .social-follow-links {
    position: absolute;
    top: 19.2px;
    right: 19.2px;
  }
  #branding .logo img {
    width: 340px;
  }
}
/**
 * Menu
 */
#menu.navbar {
  margin-bottom: 0;
  font-weight: 600;
}
#menu.navbar .navbar-toggle {
  border: 1px solid #dddddd;
}
#menu.navbar .navbar-toggle .icon-bar {
  background-color: #333333;
  transition: all 0.3s;
}
#menu.navbar .navbar-toggle:hover .icon-bar {
  background-color: #f9b3c6;
  transition: all 0.3s;
}
#menu.navbar .navbar-nav {
  display: inline-block;
  float: none;
  vertical-align: top;
}
#menu.navbar .navbar-nav > li {
  padding: 0 .5em;
}
#menu.navbar .navbar-nav > li > a:focus,
#menu.navbar .navbar-nav > li > a:hover {
  background: none;
}
#menu.navbar .navbar-collapse {
  text-align: center;
}
@media (max-width: 767px) {
  #menu {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
  }
  #menu .navbar-collapse {
    position: absolute;
    z-index: 1;
    background: #ffffff;
    width: 100%;
    border-bottom: 1px solid #dddddd;
    box-shadow: 0 5px 5px -5px rgba(34, 34, 34, 0.15);
  }
  #menu.navbar .navbar-nav {
    display: block;
    margin-top: 0;
    margin-bottom: 0;
  }
  #menu.navbar .navbar-nav > li {
    border-top: 1px solid #dddddd;
  }
  #menu.navbar .navbar-nav > li > a {
    margin-top: -1px;
  }
  #menu.navbar .navbar-nav > li:hover > a,
  #menu.navbar .navbar-nav > li.active > a {
    color: #f9b3c6;
    transition: all 0.3s;
  }
}
@media (min-width: 768px) {
  #menu.navbar .navbar-nav > li {
    border-top: 3px solid transparent;
    transition: all 0.3s;
  }
  #menu.navbar .navbar-nav > li > a {
    margin-top: -3px;
  }
  #menu.navbar .navbar-nav > li:hover,
  #menu.navbar .navbar-nav > li.active {
    border-top: 3px solid #333333;
    transition: all 0.3s;
  }
  #menu.navbar .navbar-nav > li:hover > a,
  #menu.navbar .navbar-nav > li.active > a {
    color: #f9b3c6;
    transition: all 0.3s;
  }
}
/**
 * Footer
 */
#footer {
  background: #ffffff;
  padding: 8px;
  border-top: 1px solid #dddddd;
  box-shadow: 0 0 5px rgba(34, 34, 34, 0.15);
  text-align: center;
}
#footer .footer-menu {
  margin-bottom: 0;
  font-size: 14px;
}
#footer .footer-menu li {
  padding: 0 8px;
}
#footer .footer-menu li a:hover,
#footer .footer-menu li a:focus {
  text-decoration: none;
}
#footer .social-follow-links {
  text-align: center;
  margin-top: 0;
  margin-bottom: 0;
}
#footer #copyright {
  display: block;
  font-size: 12px;
  padding: 0 16px;
}
._carousel {
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  border-radius: 3px;
  overflow: hidden;
}
._carousel .carousel-inner,
._carousel .carousel-inner .item {
  border-radius: 3px;
  overflow: hidden;
}
._carousel .item img {
  display: block;
  width: 100%;
  height: auto;
}
._carousel .carousel-control {
  min-width: 50px;
  max-width: 100px;
  opacity: 0;
  transition: opacity 0.5s;
}
._carousel .carousel-control:hover {
  opacity: 1;
  transition: opacity 0.5s;
}
._cta {
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}
._cta.narrow {
  max-width: 640px;
}
._cta .cta-title,
._cta .cta-btn {
  text-transform: uppercase;
  text-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
}
._cta .cta-title {
  font-family: "Raleway", sans-serif;
  font-size: 36px;
  color: #f9b3c6;
  text-shadow: 0 1px 1px rgba(34, 34, 34, 0.15);
}
._cta .cta-message {
  text-align: left;
}
._cta .cta-btn {
  box-sizing: border-box;
  padding: 11px 32px 9px;
  font-family: "Raleway", sans-serif;
  font-size: 1.1em;
  font-weight: 600;
  vertical-align: baseline;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  background: rgba(249, 179, 198, 0);
  border: 2px solid #222222;
  color: #222222;
  transition: background 0.15s, box-shadow 0.15s;
  margin-top: 16px;
}
._cta .cta-btn:hover {
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.15);
  background: #f9b3c6;
  color: #222222;
  transition: background 0.15s, box-shadow 0.15s;
}
._sites-list {
  position: relative;
}
._sites-list:before,
._sites-list:after {
  content: " ";
  display: table;
}
._sites-list:after {
  clear: both;
}
._sites-list .column {
  box-sizing: border-box;
  width: 100%;
}
@media (min-width: 640px) {
  ._sites-list .column {
    width: 50%;
  }
  ._sites-list .column.odd {
    float: left;
    padding-right: 16px;
  }
  ._sites-list .column.even {
    float: right;
    padding-left: 16px;
  }
}
._sites-list .site {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  width: 100%;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
}
._sites-list .site,
._sites-list .site:hover {
  text-decoration: none;
}
._sites-list .site .dummy {
  padding-top: 56.25%;
}
._sites-list .site .faux-table,
._sites-list .site .faux-table-cell {
  position: relative;
  width: 100%;
  height: 100%;
}
._sites-list .site .faux-table {
  display: table;
}
._sites-list .site .faux-table-cell {
  display: table-cell;
  vertical-align: middle;
}
._sites-list .site .header {
  position: absolute;
  text-align: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 1em;
  border-radius: 3px;
  overflow: hidden;
  background: #222222;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  transition: background 0.3s, box-shadow 0.3s;
}
._sites-list .site .header h2 {
  margin: 0;
  color: #ffffff;
  text-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  transition: color 0.3s, text-shadow 0.3s;
}
._sites-list .site .header:hover {
  background: #f9b3c6;
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.15);
  transition: background 0.3s, box-shadow 0.3s;
}
._sites-list .site .header:hover h2 {
  color: #222222;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.15);
  transition: color 0.3s, text-shadow 0.3s;
}
._dienst-teaser-list {
  position: relative;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -32px;
  bottom: -32px;
}
._dienst-teaser-list:before,
._dienst-teaser-list:after {
  content: " ";
  display: table;
}
._dienst-teaser-list:after {
  clear: both;
}
._dienst-teaser-list.two-cols .dienst,
._dienst-teaser-list.three-cols .dienst {
  width: 100%;
}
@media (min-width: 640px) {
  ._dienst-teaser-list.two-cols .dienst,
  ._dienst-teaser-list.three-cols .dienst {
    width: 50%;
  }
}
@media (min-width: 960px) {
  ._dienst-teaser-list.three-cols .dienst {
    width: 33.333%;
  }
}
._dienst-teaser-list .dienst {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: left;
  padding: 0 16px 32px;
  overflow: hidden;
  text-align: center;
}
._dienst-teaser-list .dienst,
._dienst-teaser-list .dienst:hover {
  text-decoration: none;
  color: #333333;
}
._dienst-teaser-list .dienst .dienst-article {
  position: relative;
  box-sizing: border-box;
  padding-bottom: 18px;
  outline: 1px solid rgba(0, 0, 0, 0.025);
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  border-radius: 3px;
  overflow: none;
  transition: box-shadow 0.3s;
}
._dienst-teaser-list .dienst .primary-photo,
._dienst-teaser-list .dienst .vignette {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}
._dienst-teaser-list .dienst .primary-photo {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
._dienst-teaser-list .dienst .vignette {
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 33%, rgba(0, 0, 0, 0.6) 67%, #000000 100%);
  opacity: 0.5;
  transition: opacity 0.3s;
}
._dienst-teaser-list .dienst .dienst-photo,
._dienst-teaser-list .dienst .vignette {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 3px 3px 0 0;
  overflow: none;
}
._dienst-teaser-list .dienst .vignette {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.5;
  transition: opacity 0.3s;
}
._dienst-teaser-list .dienst .faux-table,
._dienst-teaser-list .dienst .faux-table-cell {
  box-sizing: content-box;
  position: relative;
  width: 100%;
  background: transparent;
  overflow: hidden;
}
._dienst-teaser-list .dienst .faux-table {
  display: table;
  text-align: center;
}
._dienst-teaser-list .dienst .faux-table-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 16px;
}
._dienst-teaser-list .dienst .dienst-title .faux-table-cell {
  height: 48px;
}
._dienst-teaser-list .dienst .dienst-title h2 {
  font-size: 18px;
  line-height: 1.2;
  text-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  margin: 0;
}
._dienst-teaser-list .dienst .cta-btn {
  box-sizing: border-box;
  padding: 3px 10px;
  font-family: "Raleway", sans-serif;
  font-size: 1.1em;
  vertical-align: baseline;
  background: rgba(249, 179, 198, 0);
  color: rgba(34, 34, 34, 0.5);
  border: 1px solid rgba(34, 34, 34, 0.25);
  box-shadow: 0 0px 0px rgba(34, 34, 34, 0);
  border-radius: 3px;
  transition: color 0.3s, border 0.3s, box-shadow 0.3s;
}
._dienst-teaser-list .dienst .cta-btn:hover {
  color: #222222;
  border: 1px solid rgba(34, 34, 34, 0.5);
  box-shadow: 0 0px 0px rgba(34, 34, 34, 0);
  transition: color 0.15s, border 0.15s, box-shadow 0.15s;
}
._dienst-teaser-list .dienst:hover .dienst-article {
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.15);
  transition: box-shadow 0.3s;
}
._dienst-teaser-list .dienst:hover .vignette {
  opacity: 0;
  transition: opacity 0.3s;
}
._dienst-teaser-list .dienst:hover .cta-btn {
  color: #222222;
  border: 1px solid rgba(34, 34, 34, 0.5);
  box-shadow: 0 0px 0px rgba(34, 34, 34, 0);
  transition: color 0.3s, border 0.3s, box-shadow 0.3s;
}
._dienst-teaser-list .dienst:hover .cta-btn:hover {
  color: #222222;
  border: 1px solid #222222;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  transition: color 0.15s, border 0.15s, box-shadow 0.15s;
}
._fancybox-gallery-grid {
  position: relative;
  margin-left: -2.5px;
  margin-right: -2.5px;
  margin-top: -5px;
  bottom: -5px;
}
._fancybox-gallery-grid:before,
._fancybox-gallery-grid:after {
  content: " ";
  display: table;
}
._fancybox-gallery-grid:after {
  clear: both;
}
._fancybox-gallery-grid .photo-container {
  width: 100%;
}
@media (min-width: 640px) {
  ._fancybox-gallery-grid .photo-container {
    width: 50%;
  }
}
@media (min-width: 960px) {
  ._fancybox-gallery-grid .photo-container {
    width: 33.333%;
  }
}
._fancybox-gallery-grid .photo-container {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: left;
  padding: 0 2.5px 5px;
  text-align: center;
  overflow: hidden;
}
._fancybox-gallery-grid .photo-container,
._fancybox-gallery-grid .photo-container:hover {
  text-decoration: none;
  color: #333333;
}
._fancybox-gallery-grid .photo-container .photo {
  box-sizing: border-box;
  position: relative;
  background: #ffffff url(../img/loader.gif) center center no-repeat;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  border-radius: 3px;
  overflow: hidden;
  transition: box-shadow 0.3s;
}
._fancybox-gallery-grid .photo-container .primary-photo,
._fancybox-gallery-grid .photo-container .vignette,
._fancybox-gallery-grid .photo-container .overlay {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}
._fancybox-gallery-grid .photo-container .primary-photo {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
._fancybox-gallery-grid .photo-container .vignette,
._fancybox-gallery-grid .photo-container .overlay {
  opacity: 0;
  transition: opacity 0.3s;
}
._fancybox-gallery-grid .photo-container .vignette {
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 33%, rgba(0, 0, 0, 0.6) 67%, #000000 100%);
}
._fancybox-gallery-grid .photo-container .overlay {
  background: rgba(34, 34, 34, 0.5);
}
._fancybox-gallery-grid .photo-container .faux-table,
._fancybox-gallery-grid .photo-container .faux-table-cell {
  box-sizing: content-box;
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  overflow: hidden;
}
._fancybox-gallery-grid .photo-container .faux-table {
  display: table;
  text-align: center;
}
._fancybox-gallery-grid .photo-container .faux-table-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 16px;
}
._fancybox-gallery-grid .photo-container .icon {
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  margin-left: -24px;
  border-radius: 100%;
  border: 2px solid rgba(255, 255, 255, 0.8);
  background: rgba(34, 34, 34, 0.8);
  background-clip: padding-box;
  box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.15);
}
._fancybox-gallery-grid .photo-container .icon img,
._fancybox-gallery-grid .photo-container .icon svg {
  position: absolute;
  display: block;
  left: 12px;
  top: 12px;
  width: 20px;
  height: 20px;
}
._fancybox-gallery-grid .photo-container:hover .photo {
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.15);
  transition: box-shadow 0.3s;
}
._fancybox-gallery-grid .photo-container:hover .vignette,
._fancybox-gallery-grid .photo-container:hover .overlay {
  opacity: 1;
  transition: opacity 0.3s;
}
._fancybox-masonry-grid {
  position: relative;
  margin-left: -2.5px;
  margin-right: -2.5px;
  margin-top: -5px;
  bottom: -5px;
}
._fancybox-masonry-grid:before,
._fancybox-masonry-grid:after {
  content: " ";
  display: table;
}
._fancybox-masonry-grid:after {
  clear: both;
}
._fancybox-masonry-grid .photo-container {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: left;
  width: 100%;
  padding: 0 2.5px 5px;
  text-align: center;
  overflow: hidden;
}
._fancybox-masonry-grid .photo-container,
._fancybox-masonry-grid .photo-container:hover {
  text-decoration: none;
  color: #333333;
}
._fancybox-masonry-grid .photo-container .photo {
  box-sizing: border-box;
  position: relative;
  background: #ffffff url(../img/loader.gif) center center no-repeat;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  border-radius: 3px;
  overflow: hidden;
  transition: box-shadow 0.3s;
}
._fancybox-masonry-grid .photo-container .primary-photo {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 3px;
  overflow: hidden;
}
._fancybox-masonry-grid .column {
  float: left;
}
._fancybox-masonry-grid .size-1of1 {
  width: 100%;
}
._fancybox-masonry-grid .size-1of2 {
  width: 50%;
}
@media screen and (max-width: 480px) {
  ._fancybox-masonry-grid[data-columns]::before {
    content: '1 .column.size-1of1';
  }
}
@media screen and (min-width: 481px) {
  ._fancybox-masonry-grid[data-columns]::before {
    content: '2 .column.size-1of2';
  }
}
._instagram-feed {
  margin-left: -5px;
  margin-right: -5px;
  margin-top: -5px;
}
._instagram-feed #sb_instagram {
  max-width: 100% !important;
}
._instagram-feed #sb_instagram.sbi_col_1.sbi_disable_mobile #sbi_images .sbi_item {
  box-sizing: border-box;
  padding: 5px !important;
  width: 50%;
}
@media (min-width: 520px) {
  ._instagram-feed #sb_instagram.sbi_col_1.sbi_disable_mobile #sbi_images .sbi_item {
    width: 33.33333333%;
  }
}
@media (min-width: 680px) {
  ._instagram-feed #sb_instagram.sbi_col_1.sbi_disable_mobile #sbi_images .sbi_item {
    width: 25%;
  }
}
@media (min-width: 920px) {
  ._instagram-feed #sb_instagram.sbi_col_1.sbi_disable_mobile #sbi_images .sbi_item {
    width: 16.66666667%;
  }
}
._instagram-feed #sb_instagram .sbi_photo_wrap,
._instagram-feed #sb_instagram .sbi_photo {
  border-radius: 3px;
  overflow: hidden;
}
._instagram-feed #sb_instagram .sbi_photo_wrap {
  outline: 1px solid rgba(0, 0, 0, 0.025);
}
._instagram-feed #sb_instagram .sbi_photo {
  height: auto !important;
}
._instagram-feed #sb_instagram .sbi_photo img {
  display: block !important;
}
._instagram-feed #sb_instagram .sbi_photo_wrap,
._instagram-feed #sb_instagram .sbi_follow_btn a {
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  transition: box-shadow 0.15s;
}
._instagram-feed #sb_instagram .sbi_photo_wrap:hover,
._instagram-feed #sb_instagram .sbi_follow_btn a:hover {
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.15);
  transition: box-shadow 0.15s;
}
._members-teaser-list {
  position: relative;
  font-size: 0.95em;
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -32px;
  bottom: -32px;
  text-align: center;
}
._members-teaser-list .member {
  box-sizing: border-box;
  position: relative;
  display: inline-block;
  vertical-align: top;
  padding: 0 16px 32px;
  width: 100%;
}
@media (min-width: 640px) {
  ._members-teaser-list .member {
    width: 50%;
  }
}
@media (min-width: 768px) {
  ._members-teaser-list .member {
    width: 33.333%;
  }
}
._members-teaser-list .member-profile {
  box-sizing: border-box;
  outline: 1px solid rgba(0, 0, 0, 0.025);
  background: #ffffff;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  border-radius: 3px;
  overflow: none;
  transition: box-shadow 0.3s;
}
._members-teaser-list .member-profile:hover {
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.15);
  transition: box-shadow 0.3s;
}
._members-teaser-list .profile-header {
  padding: 0;
  border-radius: 3px 3px 0 0;
  overflow: none;
}
._members-teaser-list .profile-photo {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 3px 3px 0 0;
  overflow: none;
}
._members-teaser-list .profile-body {
  padding: 16px 16px 1px;
  text-align: center;
}
._members-teaser-list .profile-body .name {
  font-family: 'Cantata One', serif;
  text-transform: uppercase;
  color: #222222;
  margin-bottom: 8px;
}
._members-teaser-list .profile-body .role {
  font-family: 'Droid Serif', serif;
  font-style: italic;
  color: #999999;
  margin-bottom: 8px;
}
._portfolio-teaser-list {
  position: relative;
  margin-left: -2.5px;
  margin-right: -2.5px;
  margin-top: -5px;
  bottom: -5px;
}
._portfolio-teaser-list:before,
._portfolio-teaser-list:after {
  content: " ";
  display: table;
}
._portfolio-teaser-list:after {
  clear: both;
}
._portfolio-teaser-list .portfolio-item {
  width: 100%;
}
@media (min-width: 640px) {
  ._portfolio-teaser-list .portfolio-item {
    width: 50%;
  }
}
@media (min-width: 960px) {
  ._portfolio-teaser-list .portfolio-item {
    width: 33.333%;
  }
}
._portfolio-teaser-list .portfolio-item {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: left;
  padding: 0 2.5px 5px;
  text-align: center;
  overflow: hidden;
}
._portfolio-teaser-list .portfolio-item,
._portfolio-teaser-list .portfolio-item:hover {
  text-decoration: none;
  color: #333333;
}
._portfolio-teaser-list .portfolio-item .portfolio-item-article {
  box-sizing: border-box;
  position: relative;
  background: #ffffff url(../img/loader.gif) center center no-repeat;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  border-radius: 3px;
  overflow: hidden;
  transition: box-shadow 0.3s;
}
._portfolio-teaser-list .portfolio-item .primary-photo,
._portfolio-teaser-list .portfolio-item .vignette,
._portfolio-teaser-list .portfolio-item .overlay {
  box-sizing: border-box;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 3px;
  overflow: hidden;
}
._portfolio-teaser-list .portfolio-item .primary-photo {
  background-color: transparent;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
._portfolio-teaser-list .portfolio-item .vignette,
._portfolio-teaser-list .portfolio-item .overlay {
  opacity: 0;
  transition: opacity 0.3s;
}
._portfolio-teaser-list .portfolio-item .vignette {
  background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.2) 33%, rgba(0, 0, 0, 0.6) 67%, #000000 100%);
}
._portfolio-teaser-list .portfolio-item .overlay {
  background: rgba(34, 34, 34, 0.5);
}
._portfolio-teaser-list .portfolio-item .faux-table,
._portfolio-teaser-list .portfolio-item .faux-table-cell {
  box-sizing: content-box;
  position: relative;
  width: 100%;
  height: 100%;
  background: transparent;
  overflow: hidden;
}
._portfolio-teaser-list .portfolio-item .faux-table {
  display: table;
  text-align: center;
}
._portfolio-teaser-list .portfolio-item .faux-table-cell {
  display: table-cell;
  vertical-align: middle;
  padding: 16px;
}
._portfolio-teaser-list .portfolio-item .title {
  color: #ffffff;
  margin-bottom: 0;
}
._portfolio-teaser-list .portfolio-item:hover .portfolio-item-article {
  box-shadow: 0 2px 6px rgba(34, 34, 34, 0.15);
  transition: box-shadow 0.3s;
}
._portfolio-teaser-list .portfolio-item:hover .vignette,
._portfolio-teaser-list .portfolio-item:hover .overlay {
  opacity: 1;
  transition: opacity 0.3s;
}
.social-follow-links,
.social-share-links {
  position: relative;
  display: block;
  margin: -4px;
}
.social-follow-links > a,
.social-share-links > a {
  display: inline-block;
  box-sizing: content-box;
  text-align: center;
  width: 16px;
  height: 16px;
  font-size: 16px;
  line-height: 16px;
  padding: 7px 6px 5px;
  margin: 4px;
  vertical-align: baseline;
  font-family: 'socicon' !important;
  color: #ffffff;
  background: #222222;
  border-radius: 4.5px;
  text-decoration: none;
  text-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
  transition: color 0.3s, background 0.3s, text-shadow 0.3s;
}
.social-follow-links > a:hover,
.social-share-links > a:hover {
  color: #222222;
  background: #f9b3c6;
  text-shadow: 0 1px 2px rgba(255, 255, 255, 0.15);
  transition: color 0.3s, background 0.3s, text-shadow 0.3s;
  text-decoration: none;
}
.social-follow-links.small > a,
.social-share-links.small > a {
  width: 12px;
  height: 12px;
  font-size: 12px;
  line-height: 12px;
  padding: 5px;
  border-radius: 3px;
}
.social-follow-links.large > a,
.social-share-links.large > a {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
  padding: 8px 7px 6px;
  border-radius: 6px;
}
.social-share-links > a {
  border-radius: 100%;
  padding: 10px;
}
.page--front {
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .page--front {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  .page--front {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.page--diensten {
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .page--diensten {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  .page--diensten {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.page--about {
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .page--about {
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  .page--about {
    padding-bottom: 40px;
  }
}
.page--about .section--header {
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .page--about .section--header {
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  .page--about .section--header {
    padding-bottom: 40px;
  }
}
.page--about .section--header .photo {
  width: 100%;
  height: auto;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
}
.page--contact {
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .page--contact {
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  .page--contact {
    padding-bottom: 40px;
  }
}
.page--contact .section--header {
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .page--contact .section--header {
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  .page--contact .section--header {
    padding-bottom: 40px;
  }
}
.page--contact .section--header .photo {
  width: 100%;
  height: auto;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
}
.page--contact .section--content .row {
  margin-left: -16px;
  margin-right: -16px;
  margin-top: -32px;
  bottom: -32px;
}
.page--contact .section--content .col {
  box-sizing: border-box;
  position: relative;
  display: block;
  float: right;
  padding: 0 16px 32px;
  width: 100%;
}
@media (min-width: 640px) {
  .page--contact .section--content .col {
    width: 50%;
  }
}
.page--contact .section--content .wpcf7-form > p {
  font-weight: 600;
  margin: 0;
}
.archive--portfolio {
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .archive--portfolio {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  .archive--portfolio {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.entry--dienst {
  padding-top: 24px;
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .entry--dienst {
    padding-top: 32px;
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  .entry--dienst {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.entry--dienst.has-header-photo {
  padding-top: 0;
}
@media (min-width: 768px) {
  .entry--dienst.has-header-photo {
    padding-top: 0;
  }
}
@media (min-width: 960px) {
  .entry--dienst.has-header-photo {
    padding-top: 0;
  }
}
.entry--dienst .section--header {
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .entry--dienst .section--header {
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  .entry--dienst .section--header {
    padding-bottom: 40px;
  }
}
.entry--dienst .section--header .photo {
  width: 100%;
  height: auto;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
}
.entry--dienst .section--content ._carousel {
  margin-bottom: 32px;
}
@media (min-width: 768px) {
  .entry--dienst .section--content ._carousel {
    margin-bottom: 48px;
  }
}
@media (min-width: 960px) {
  .entry--dienst .section--content ._carousel {
    margin-bottom: 64px;
  }
}
.entry--dienst .section--content .share-icons {
  text-align: center;
}
.entry--portfolio {
  padding-bottom: 0;
}
@media (min-width: 768px) {
  .entry--portfolio {
    padding-bottom: 0;
  }
}
@media (min-width: 960px) {
  .entry--portfolio {
    padding-bottom: 0;
  }
}
.entry--portfolio .section--header {
  padding-bottom: 24px;
}
@media (min-width: 768px) {
  .entry--portfolio .section--header {
    padding-bottom: 32px;
  }
}
@media (min-width: 960px) {
  .entry--portfolio .section--header {
    padding-bottom: 40px;
  }
}
.entry--portfolio .section--header .photo {
  width: 100%;
  height: auto;
  box-shadow: 0 1px 2px rgba(34, 34, 34, 0.15);
}
.entry--portfolio .section--content .share-icons {
  text-align: center;
}
.entry--portfolio .section--gallery .fullwidth,
.entry--portfolio .section--gallery .halfwidth {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 50%;
}
.entry--portfolio .section--gallery .fullwidth .photo,
.entry--portfolio .section--gallery .halfwidth .photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
}
.entry--portfolio .section--gallery .halfwidth .photo:first-child {
  width: 50%;
}
.entry--portfolio .section--gallery .halfwidth .photo:last-child {
  left: 50%;
  width: 50%;
}
